import { api } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetTopups,
} from './mutations';
import { AppNotification, MainState } from './state';
import {ITopup} from '@/interfaces';

type MainContext = ActionContext<MainState, State>;
enum TopUpType {
    AIS,
    DTAC,
    TRUE,
};

function getValidAtomicTopupAmounts(type: TopUpType): number[] {
    let ret: number[] = [];
    // got these from WePAY api
    if (type == TopUpType.AIS) {
        ret = [
            10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250,
            300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1500,
        ];
    } else if (type == TopUpType.DTAC) {
        ret = [10, 20, 30, 40, 50, 60, 100, 200, 300, 500, 800];
    } else if (type == TopUpType.TRUE) {
        ret = [
            10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140,
            150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270,
            280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400,
            410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530,
            540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660,
            670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790,
            800, 810, 820, 830, 840, 850, 860, 870, 880, 890, 900, 910, 920,
            930, 940, 950, 960, 970, 980, 990, 1000
        ];
    }

    return ret;
}

async function _actionTopup(type: TopUpType, context: MainContext, payload: ITopup) {
    const p = context.state.userProfile!;
    const loadingNotification = { content: 'Topping up', showProgress: true };
    if (payload.amount % 10 !== 0) {
        commitAddNotification(context, { color: 'error', content: 'The amount must be divisible by 10.' });
        return;
    }
    if (payload.amount * p.discount_factor > p.balance - p.min_balance) {
        commitAddNotification(context, { color: 'error', content: 'Insufficient available balance.' });
        return;
    }

    const amount = payload.amount;
    let amountToTopUp = amount;
    try {
        commitAddNotification(context, loadingNotification);

        const validAtomicTopupAmounts: number[] = getValidAtomicTopupAmounts(type);
        let biggestAmt: number = validAtomicTopupAmounts.pop()!;
        while (amountToTopUp > 0) {
            // find the next biggest top up amount
            while (biggestAmt > amountToTopUp) {
                biggestAmt = validAtomicTopupAmounts.pop()!;
            }

            payload.amount = biggestAmt;
            
            let response: AxiosResponse<any, any>;
            if (type == TopUpType.AIS) {
                response = await api.topupAis(context.state.token, payload);
            } else if (type == TopUpType.DTAC) {
                response = await api.topupDtac(context.state.token, payload);
            } else if (type == TopUpType.TRUE) {
                response = await api.topupTrue(context.state.token, payload);
            }
            amountToTopUp -= biggestAmt;
            // effectively update user balance
            await dispatchGetUserProfile(context);
            // update topups history
            await dispatchGetTopups(context);
        }

        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, { content: 'Topped up successfully', color: 'success' });
    } catch (error) {
        commitRemoveNotification(context, loadingNotification);
        let errorMsg = 'unknown';
        if (axios.isAxiosError(error)) {
            if (error.response && ('detail' in (error.response!.data as any))) {
                errorMsg = (error.response!.data as any).detail;
            }
        } else {
            errorMsg = error as string;
        }
        if (amountToTopUp !== amount) {
            commitAddNotification(
                context,
                { color: 'warning',
                  content: 'Topped up ' + ( amount - amountToTopUp ) + ' of ' + amount +
                    ' and got the following error: \n' + errorMsg,
                },
            );
        } else {
            commitAddNotification(context, { color: 'error', content: errorMsg });
        }
    }
}

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error as any);
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error as any);
        }
    },
    async actionTopupAis(context: MainContext, payload: ITopup) {
        return await _actionTopup(TopUpType.AIS, context, payload);
    },
    async actionTopupDtac(context: MainContext, payload: ITopup) {
        return await _actionTopup(TopUpType.DTAC, context, payload);
    },
    async actionTopupTrue(context: MainContext, payload: ITopup) {
        return await _actionTopup(TopUpType.TRUE, context, payload);
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push('/main');
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    async actionGetTopups(context: MainContext) {
        try {
            const response = await api.getTopups(context.state.token);
            if (response) {
                commitSetTopups(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error as any);
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchTopupAis = dispatch(actions.actionTopupAis);
export const dispatchTopupDtac = dispatch(actions.actionTopupDtac);
export const dispatchTopupTrue = dispatch(actions.actionTopupTrue);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetTopups = dispatch(actions.actionGetTopups);
