<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Top Up</div>
      </v-card-title>
      <v-card-text>
        <div class="float-left">
          <div class="subheading secondary--text text--lighten-3">Balance / Min Balance / Available Balance (THB)</div>
            <div class="title primary--text text--darken-2" v-if="userProfile">
              {{userProfile.balance.toFixed(2)}} /
              {{userProfile.min_balance.toFixed(2)}} /
              {{(userProfile.balance - userProfile.min_balance).toFixed(2)}}
            </div>
          <div class="title primary--text text--darken-2" v-else>-- / -- / --</div>
        </div>
        <template>
          <v-form ref="form">
            <v-select
              required
              label="Operator"
              v-model="operator"
              :items="operatorItems"
              @input="$v.operator.$touch()"
              @blur="$v.operattor.$touch()"
              ></v-select>
            <v-text-field
              required
              label="Phone Number: "
              v-model="phone"
              hint="example: 0123456789"
              :error-messages="phoneErrors"
              @input="$v.phone.$touch()"
              @blur="$v.phone.$touch()"
              ></v-text-field>
            <v-text-field
              required
              label="Amount"
              v-model="amount"
              :error-messages="amountErrors"
              @input="$v.amount.$touch()"
              @blur="$v.amount.$touch()"
              hint="A number divisible by 10"
              ></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn @click="submit" :disabled="$v.$invalid">
          Top Up
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="subheading secondary--text text--lighten-3">Top-Up History</div>
    <v-data-table :headers="headers"
                  :items="topups"
                  :must-sort="true"
                  :custom-sort="customSort">
      <template v-slot:items="props">
        <td>{{ props.item.created_at }}</td>
        <td>{{ props.item.type }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.destination }}</td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { api } from '@/api';
import { Component, Vue } from 'vue-property-decorator';
import { ITopup } from '@/interfaces';
import { readUserProfile, readTopups } from '@/store/main/getters';
import { dispatchTopupAis, dispatchTopupDtac, dispatchTopupTrue, dispatchGetTopups } from '@/store/main/actions';
import { required } from 'vuelidate/lib/validators';

@Component
export default class Topup extends Vue {
  operatorItems = ['AIS', 'DTAC', 'TRUE'];
  operator = 'AIS';
  phone: string = '';
  amount: number = 10;
  headers = [
    {
      text: 'Time',
      value: 'created_at',
      align: 'left',
    },
    {
      text: 'Type',
      value: 'type',
      align: 'left',
    },
    {
      text: 'Amount',
      value: 'amount',
      align: 'left',
    },
    {
      text: 'Phone Number',
      value: 'destination',
      align: 'left',
    },
  ];

  validations() {
    return {
      operator: { required },
      phone: {
        required,
        validFormat(value: string) {
          return /0[0-9]{9,9}/.test(value);
        },
// TODO: need to fix the root cause
/*
        validOperator: async (value: string) => {
          try {
            if (this.operator == 'AIS') {
              const response = await api.verifyNumber(this.$store.state.main.token, value);
              return response.data.carrier === this.operator;
            } else {
              // we do not verify True and Dtac phone numbers for now
              return true;
            }
          } catch (e) {
            return false;
          }
        }
*/
      },
      amount: {
        required,
        div10(value) { return value % 10 === 0; },
      },
    };
  }

  get phoneErrors() {
    if (! this.$v.phone.required)
      return ['Required'];
    if (! this.$v.phone.validFormat)
      return ['Invalid phone number'];
    // TODO: Need to fix the root cause.
    //if (! this.$v.phone.validOperator)
    //  return ['The operator of this phone number is not ' + this.operator];
  }

  get amountErrors() {
    if (! this.$v.amount.required)
      return ['Required'];
    if (! this.$v.amount.div10)
      return ['The amount must be divisible by 10.'];
  }

  public customSort(items, index, isDesc) {
    items.sort((a, b) => {
      if (isDesc) {
        return a[index] < b[index] ? -1 : 1;
      } else {
        return b[index] < a[index] ? -1 : 1;
      }
    });
    return items;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    this.$v.$touch();
    if (! this.$v.$invalid) {
      const topupData: ITopup = {
        amount: this.amount,
        phone: this.phone,
      };
      return await {
        'AIS': dispatchTopupAis,
        'DTAC': dispatchTopupDtac,
        'TRUE': dispatchTopupTrue,
      }[this.operator](this.$store, topupData);
    }
  }

  get topups() {
    return readTopups(this.$store);
  }

  // TODO: need to implement dynamic pagination
  //       See https://vuetifyjs.com/en/components/data-tables/#server-side-paginate-and-sort
  //       and https://blog.logrocket.com/vue-typescript-tutorial-examples/
  async mounted() {
    await dispatchGetTopups(this.$store);
  }
}
</script>
